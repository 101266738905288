// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');

@font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-Regular.ttf') format('opentype');
}

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
